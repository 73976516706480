import React from 'react';

import Button from 'react-bootstrap/Button';

function PrimaryButton(props) {
  return <Button
    {...props}
    style={{width: '100%', height: 44, cursor: props.disabled ? 'not-allowed' : null}}
  >
    {props.children}
  </Button>
}

export default PrimaryButton;
