import React from 'react';

import FormControl from 'react-bootstrap/FormControl';

function TextField (props) {

  return <div style={{display: 'flex'}}>
    <div style={{padding: 10, paddingTop: 16, paddingLeft: 0}}>
      <i style={{opacity: 0.3}} className="material-icons-outlined">{props.icon}</i>
    </div>
    <div style={{width: '100%'}}>
      {
        (props.hasText && props.title) && <div style={{paddingTop: 8, paddingLeft: 13, fontSize: 12, color: '#555', position: 'absolute'}}>
          {props.title}
        </div>
      }
      <FormControl
        placeholder={props.placeholder || props.title}
        style={{
          width: '100%',
          cursor: (props.disabled && 'not-allowed'),
          height: 56,
          paddingTop: props.hasText && 18,
          backgroundColor: 'rgba(57,57,57,0.1)'
        }}
        disabled={props.disabled}
        {...props.controlProps}
      >
        {props.children}
      </FormControl>
      {props.footerText && <div style={{fontSize: 10}}>
        {props.footerText}
      </div>}
    </div>
  </div>
}

export default TextField;
