import React from 'react';

import { Router, Route, Switch } from 'react-router';
import { history } from './history';
import qs from 'qs';

import OAuthAuthorizeCheck from './components/OAuthAuthorizeCheck';
import UserLogin from './components/UserLogin';
import UserRegister from './components/UserRegister';
import GuestRegister from './components/GuestRegister';
import RefundConfirm from './components/RefundConfirm';
import OAuthRedirect from './components/OAuthRedirect';
import PhoneVerify from './components/PhoneVerify';
import { Cancel, Cancelled } from './components/Cancel';
import { Reschedule, Rescheduled } from './components/Reschedule';

import _ from 'lodash';

import '@blueprintjs/core/lib/css/blueprint.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/oauth/authorize"
          exact
          render={(props) => {
            const { 
              client_id,
              state,
              items,
              redirect_uri,
              course_of_action,
              course_of_action_description,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            if (!items || !items[0]) {
              return <pre>
                {JSON.stringify({
                  error: 'At least one item is required',
                }, null, 2)}
              </pre>
            }

            const missingTitle = _.find(items, (i) => (!i.title));

            // check for items
            if (missingTitle) {
              return <pre>
                {JSON.stringify({
                  error: 'One of your items is missing a title.',
                  item: missingTitle,
                }, null, 2)}
              </pre>
            }

            const VALID_COURSE_OF_ACTION = [
              'recycle',
              'donate',
              'return_to_partner',
              'custom',
            ];
            if (!course_of_action || !VALID_COURSE_OF_ACTION.includes(course_of_action)) {
              return <pre>
                {JSON.stringify({
                  error: 'course_of_action field is missing or invalid',
                  item: missingTitle,
                }, null, 2)}
              </pre>
            }

            if ((course_of_action === "custom") && !course_of_action_description) {
              return <pre>
                {JSON.stringify({
                  error: 'If course_of_action is set as custom, course_of_action_description is a required field',
                  item: missingTitle,
                }, null, 2)}
              </pre>              
            }

            return <OAuthAuthorizeCheck
              clientId={client_id} 
              state={state}
              redirectURI={redirect_uri}
              params={props.location.search}
            />
          }}
        />
        <Route
          path="/login"
          exact
          render={(props) => {
            return <UserLogin
              params={props.location.search}
            />
          }}
        />
        <Route
          path="/register"
          exact
          render={(props) => {
            return <UserRegister
              params={props.location.search}
            />
          }}
        />
        <Route
          path="/guest"
          exact
          render={(props) => {
            const { 
              address,
              items,
              course_of_action,
              course_of_action_description,
              // need to pass thru
              redirect_uri,
              client_id,
              state,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <RefundConfirm
              guest={true}
              address={address}
              items={items}
              courseOfAction={course_of_action}
              courseOfActionDescription={course_of_action_description}
              //
              redirectURI={redirect_uri}
              clientId={client_id}
              state={state}
              params={props.location.search}
            />
          }}
        />
        {
          // protected
        }
        <Route
          path="/refund"
          exact
          render={(props) => {
            const { 
              address,
              items,
              course_of_action,
              course_of_action_description,              
              // need to pass thru
              redirect_uri,
              client_id,
              state,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <RefundConfirm
              guest={false}
              address={address}
              items={items}
              courseOfAction={course_of_action}
              courseOfActionDescription={course_of_action_description}
              //
              redirectURI={redirect_uri}
              clientId={client_id}
              state={state}
              params={props.location.search}
            />
          }}
        />
        <Route
          path="/verify"
          exact
          render={(props) => {
            const { 
              return_id,
              phone_number,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <PhoneVerify
              params={props.location.search}
              returnId={return_id}
              phoneNumber={phone_number}
            />
          }}
        />
        <Route
          path="/oauth/redirect"
          exact
          render={(props) => {
            const { 
              redirect_uri,
              client_id,
              return_id,
              state,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <OAuthRedirect
              clientId={client_id}
              returnId={return_id}
              redirectURI={redirect_uri}
              state={state}
            />
          }}
        />
        {
          // afterwards
        }
        <Route
          path="/cancel"
          exact
          render={(props) => {
            const { 
              token,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <Cancel
              token={token}
            />
          }}
        />
        <Route
          path="/cancelled"
          exact
          render={(props) => {
            const { 
              token,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <Cancelled
              token={token}
            />
          }}
        />        
        <Route
          path="/reschedule"
          exact
          render={(props) => {
            const { 
              token,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <Reschedule
              token={token}
            />
          }}
        />
        <Route
          path="/rescheduled"
          exact
          render={(props) => {
            const { 
              token,
            } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

            return <Rescheduled
              token={token}
            />
          }}
        />        
      </Switch>
    </Router>
    
  );
}

export default App;
