import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
  Redirect
} from 'react-router-dom';

import PollenContainer from './PollenContainer';
import TextField from './TextField';
import PrimaryButton from './PrimaryButton';

const loginUser = (email, password, setData, setError, setSubmitting) => {
  axios.post('/api/users/login', {
    email,
    password,
  }).then((resp) => {
    setData(resp.data);
  }).catch((e) => {
    if (e.response && e.response.data.error) {
      setError(e.response.data.error);
      setSubmitting(false);
    }
  });
}

export default function UserLogin(props) {
  const { params } = props;

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  if (data) {
    return <Redirect
      to={`/refund${params}`}
    />
  } else {
    return <PollenContainer
      title="Log in with Pollen"
      subtitle="Pollen is the easiest way to make returns"
    >
      <Formik
        initialValues={{email: '', password: ''}}
        onSubmit={(values, { setSubmitting }) => {
          loginUser(values.email, values.password, setData, setError, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .min(1)
            .required('Required'),
          password: Yup.string(),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            setSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          return <Container>
            <Row>
              <Col>
                { error && <div style={{paddingLeft: 20, width: '100%'}}>
                    <Alert variant="danger">
                      {error}
                    </Alert>
                  </div> 
                }
              </Col>
            </Row>
            <Row style={{marginTop: 20, maxWidth: 500}}>
              <Col>
                <form>
                  <Row>
                    <Col>
                      <TextField
                        icon="mail"
                        title="Email"
                        hasText={!!values.email}
                        controlProps={{
                          id: "email",
                          onChange: handleChange,
                          value: values.email,
                          autoComplete: "off",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 30}}>
                    <Col>
                      <TextField
                        icon="https"
                        title="Password"
                        hasText={!!values.password}
                        controlProps={{
                          id: "password",
                          onChange: handleChange,
                          value: values.password,
                          type: "password",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 32}}>
                    <Col>
                      <PrimaryButton
                        type="submit" 
                        variant="primary" 
                        disabled={isSubmitting || !values.password || !values.email} 
                        onClick={handleSubmit}
                      >
                        <b>Log in</b>
                      </PrimaryButton> 
                    </Col>
                  </Row>
                  <Row style={{marginTop: 16}}>
                    <Col>
                      <Button
                        variant="link"
                        style={{width: '100%'}}
                      >
                        Forgot Password?
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{marginTop: 6}}>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 16}}>
                    <Col>
                      <Button 
                        style={{width: '100%', backgroundColor: '#D9D9D9', color: '#555555', height: 44}} 
                        variant="light" 
                        href={`/register${params}`} 
                      >
                        <b>Register</b>
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{marginTop: 6}}>
                    <Col style={{textAlign: 'center'}}>
                      Or{' '} 
                      <a
                        href={`/guest${params}`}
                      >
                        return as a guest
                      </a>.
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        }}
      </Formik>
    </PollenContainer>
  }
}
