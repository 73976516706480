import React from 'react';

import axios from 'axios';
import { 
  Spinner,
} from '@blueprintjs/core';
import {
  Redirect
} from 'react-router-dom';


export default class OAuthRedirect extends React.Component {

  state = {
    data: null,
  }

  getAuthorizationCode = () => {
    axios.post('/api/tokens/authorization_code', {
      return_id: this.props.returnId,
      client_id: this.props.clientId,
      redirect_uri: this.props.redirectURI
    }).then((resp) => {
      window.location = `${this.props.redirectURI}?code=${resp.data.authorization_code}&state=${this.props.state}`;
    }).catch((e) => {
      // display error
      console.warn(e);
    });
  }

  componentDidMount() {
    // perform fetch
    this.getAuthorizationCode();
  }

  render(){
    return <Spinner />
  }
}
