import React from 'react';

import axios from 'axios';
import { 
  Spinner,
} from '@blueprintjs/core';
import {
  Redirect
} from 'react-router-dom';


export default class OAuthAuthorizeCheck extends React.Component {

  state = {
    data: null,
    error: null,
  }

  fetchPartner = () => {
    // check redirect uri as well
    axios.post(`/api/partners/${this.props.clientId}/check`, {
      redirect_uri: this.props.redirectURI,
    }).then((resp) => {
      this.setState({
        data: resp.data
      });
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }
    });
  };

  componentDidMount() {
    // perform fetch
    if(this.props.clientId) {
      this.fetchPartner();
    }
  }

  render(){
    if (this.state.error) {
      return <pre>
        {JSON.stringify(this.state.error, null, 2)}
      </pre>
    } else if (this.state.data && this.state.data.logged_in) {
      return <Redirect to={`/refund${this.props.params}`} />
    } else if (this.state.data && !this.state.data.logged_in) {
      return <Redirect to={`/guest${this.props.params}`} />
    } else {
      return <Spinner />
    }
  }
}
