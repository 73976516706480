import React from 'react';

import axios from 'axios';
import PrimaryButton from './PrimaryButton';
import { Container, Row, Col, FormControl } from 'react-bootstrap';
import { Popover } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import TextField from './TextField';

import { 
  Spinner,
} from '@blueprintjs/core';
import moment from 'moment';
import {
  Redirect
} from 'react-router-dom';
import { Formik } from 'formik';

import { getMinTimes } from '../lib/time';
import { loadScript, handleScriptLoad } from '../lib/gmaps';

import _ from 'lodash';

const GOOGLE_API_KEY = 'AIzaSyAlhM86-6ztDX7Bkb99mVhNpXnrb8POT6c';

export class Reschedule extends React.Component {
  autoCompleteRef = React.createRef();

  state = {
    loading: true,
    data: null,
    locked: false,
    rescheduled: false,
    //
    pickupDate: null,
    pickupDateDialog: false,
    //
    addressQuery: "",
    //
    address: null, // geometry.location.{lat, lng}, formatted_address, address_components
  }

  setAddressQuery = (addressQuery) => {
    this.setState({
      addressQuery,
    });
  }

  setAddress = (address) => {
    this.setState({
      address,
    });
  }

  componentDidMount() {
    axios.get(`/api/email-tokens/${this.props.token}/return`).then((resp) => {
      this.setState({
        loading: false,
        data: resp.data
      });

      const addressOnly = true;
  
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(addressOnly, (update) => {
          this.setAddress(update);
          this.setAddressQuery(update.formatted_address);
        }, this.autoCompleteRef, () => {
          if (this.props.address) {
            // default lookup
            new window.google.maps.places.AutocompleteService().getPlacePredictions(
              {input: this.props.address}, 
              (predictions) => {
                if (predictions[0]) {
                  new window.google.maps.places.PlacesService(this.autoCompleteRef.current).getDetails({
                    placeId: predictions[0].place_id,
                    fields: ["address_components", "formatted_address", "geometry.location"]
                  },
                  (update) => {
                    this.setAddress(update);
                    this.setAddressQuery(update.formatted_address);
                  })
                }
              });
          }
        })
      );
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }
    });
  }

  setPickupDate = (pickupDate) => {
    this.setState({
      pickupDate,
    });

    this.closePickupDateDialog();
  }

  openPickupDateDialog = () => {
    this.setState({
      pickupDateDialog: true,
    });
  }

  closePickupDateDialog = () => {
    this.setState({
      pickupDateDialog: false,
    });
  }

  reschedule = ({newTime, address, address2}) => {
    this.setState({
      locked: true,
    });

    let addressPayload = {};

    if(address) {
      const lat = address.geometry.location.lat();
      const lng = address.geometry.location.lng();

      // break down components
      const addressComponents = address.address_components;
      const streetNumber = _.find(addressComponents, (item) => (item.types.includes("street_number"))) || {}
      const streetName = _.find(addressComponents, (item) => (item.types.includes("route"))) || {}
      const city = _.find(addressComponents, (item) => (item.types.includes("locality"))) || {}
      const state = _.find(addressComponents, (item) => (item.types.includes("administrative_area_level_1"))) || {}
      const zip = _.find(addressComponents, (item) => (item.types.includes("postal_code"))) || {}    

      addressPayload = {
        address: address.formatted_address,
        address_line1: `${streetNumber.short_name} ${streetName.short_name}`,
        address_line2: address2,
        address_city: city.long_name,
        address_state: state.short_name,
        address_zip: zip.short_name,
        lat,
        lng,
      }
    }

    axios.post(`/api/email-tokens/${this.props.token}/reschedule`, {
      time: newTime.getTime(),
      ...addressPayload
    }).then((resp) => {
      // goto new page
      this.setState({
        locked: false,
        rescheduled: true,
      })
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }
    });
  }

  render() {
    if (!this.props.token) {
      return <div>Error: unauthorized</div>
    }

    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.rescheduled) {
      return <Redirect to={`/rescheduled?token=${this.props.token}`} />
    }

    const { validTimes, pickupMoment, minDate } = getMinTimes(moment(), this.state.pickupDate);

    return <Container style={{marginTop: 32, maxWidth: 800}}>
      <Row>
        <Col>
          <Container>
            Please select a new time for your pickup.
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
          <Container>
            <Row><Col style={{fontWeight: 'bold'}}>Date</Col><Col>{moment(this.state.data.pickup_window_start).format('ddd. MMM. DD YYYY')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Time</Col><Col>{moment(this.state.data.pickup_window_start).format('hh:mm A')} - {moment(this.state.data.pickup_window_end).format('hh:mm A')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Address</Col><Col>{this.state.data.address.formatted}</Col></Row>
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
          <Formik
            initialValues={{
              pickupTime: validTimes[0].value,
            }}
            onSubmit={(values) => {
              const pickup = pickupMoment.toDate();
              pickup.setHours(values.pickupTime);

              this.reschedule({
                newTime: pickup,
                address: this.state.address,
                address2: values.address2,
              });
            }}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              // date calculations
              
              return <form autoComplete="off" autoCorrect="off">
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: 10, paddingTop: 16, paddingLeft: 0}}>
                            <i style={{opacity: 0.3}} className="material-icons-outlined">event</i>
                          </div>
                          <Popover 
                            minimal
                            fill
                            autoFocus={false}
                            position="bottom-left"
                            isOpen={this.state.pickupDateDialog}
                            onClose={this.closePickupDateDialog}
                            backdropProps={{
                              style: {width: '100%'}
                            }}
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: false,
                                boundariesElement: document.body,
                              },
                            }}
                          >
                            <div>
                              { 
                                <div style={{paddingTop: 8, paddingLeft: 13, fontSize: 12, color: '#555', position: 'absolute'}}>
                                Date 
                              </div> }
                              <FormControl 
                                style={{
                                  height: 56,
                                  paddingTop: 18,
                                  backgroundColor: 'rgba(57,57,57,0.1)'
                                }}
                                placeholder="Date"
                                value={pickupMoment.format('ddd. MMM. DD YYYY')}
                                onClick={this.openPickupDateDialog}
                              />
                            </div>
                            <div style={{minWidth: 300}}>
                              <DatePicker
                                onChange={this.setPickupDate}
                                value={pickupMoment.toDate()}
                                minDate={minDate.toDate()}
                                highlightCurrentDay={true}
                              />
                            </div>
                          </Popover>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="schedule"
                          title="Time"
                          hasText={!!values.pickupTime}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "pickupTime",
                            as: "select",
                            onChange: handleChange,
                            value: values.pickupTime,
                          }}
                        >
                          {validTimes.map((d) => {
                            return <option value={d.value}>{d.text}</option>
                          })}
                        </TextField>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="location_on"
                          title="Change address"
                          hasText={!!this.state.addressQuery.length}
                          disabled={this.state.loading}
                          controlProps={{
                            onChange: (event) => (this.setAddressQuery(event.target.value)),
                            value: this.state.addressQuery,
                            ref: this.autoCompleteRef,
                            autoComplete: "off",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="home"
                          title="Apartment, floor, etc."
                          placeholder="Apartment, floor, etc. (Optional)"
                          hasText={!!(values.address2 || '').length}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "address2",
                            onChange: handleChange,
                            value: values.address2,
                            autoComplete: "new-password",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <PrimaryButton 
                          type="submit"
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={!values.pickupTime}
                        >
                          <b>Reschedule Pickup</b>
                        </PrimaryButton>
                      </Col>
                    </Row>                    
                  </form>
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  }
}

export class Rescheduled extends React.Component {
  state = {
    loading: true,
    data: null,
  }

  componentDidMount() {
    axios.get(`/api/email-tokens/${this.props.token}/return`).then((resp) => {
      this.setState({
        loading: false,
        data: resp.data
      });
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }
    });    
  }

  render() {
    if (!this.props.token) {
      return <div>Error: unauthorized</div>
    }

    if (this.state.loading) {
      return <Spinner />;
    }

    return <Container style={{marginTop: 32, maxWidth: 800}}>
      <Row>
        <Col>
          <Container>
            Your return has been rescheduled.
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
          <Container>
            <Row><Col style={{fontWeight: 'bold'}}>Date</Col><Col>{moment(this.state.data.pickup_window_start).format('ddd. MMM. DD YYYY')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Time</Col><Col>{moment(this.state.data.pickup_window_start).format('hh:mm A')} - {moment(this.state.data.pickup_window_end).format('hh:mm A')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Address</Col><Col>{this.state.data.address.formatted}</Col></Row>
          </Container>
        </Col>
      </Row>
    </Container>
  }
}
