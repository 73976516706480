import React, { useRef, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
  Redirect
} from 'react-router-dom';

import ReactCodeInput from 'react-code-input';

import PollenContainer from './PollenContainer';
import PrimaryButton from './PrimaryButton';


const phoneVerify = (returnId, phoneNumber, verificationCode, setData, setError, clearVerificationCode) => {
  axios.post(`/api/returns/${returnId}/verify`, {
    phone_number: phoneNumber,
    verification_code: verificationCode,
  }).then((resp) => {
    setData(resp.data);
  }).catch((e) => {
    if (e.response && e.response.data) {
      clearVerificationCode();
      setError(e.response.data);
      // setSubmitting(false);
    }
  });
};

const resendCode = (returnId, phoneNumber) => {
  axios.post(`/api/returns/${returnId}/verification`, {
    phone_number: phoneNumber,
  });
}

export default function PhoneVerify(props) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const codeRef = useRef(undefined);

  // HACK because react-code-input does not directly support resets
  function clearVerificationCode() {
    setVerificationCode('');
    if(codeRef.current.textInput[0]) codeRef.current.textInput[0].focus()
    codeRef.current.state.input[0] = ""
    codeRef.current.state.input[1] = ""
    codeRef.current.state.input[2] = ""
    codeRef.current.state.input[3] = ""
    codeRef.current.state.input[4] = ""
    codeRef.current.state.input[5] = ""
  }

  if (data) {
    // do redirect
    const redirect = `/oauth/redirect${props.params}`
    return <Redirect to={redirect} />
  } else {
    const { innerHeight, innerWidth } = window;
    const isPortrait = innerWidth < innerHeight;

    const maybeMaxHeight = isPortrait ? {
      overflowY: 'scroll',
      maxHeight: (innerHeight - 240)
    } : {};

    const inputStyle = {
      fontFamily: 'monospace',
      MozAppearance: 'textfield',
      borderRadius: '6px',
      border: '1px solid',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
      margin: '4px',
      paddingLeft: '12px',
      paddingRight: 0,
      width: '36px',
      height: '42px',
      fontSize: '20px',
      boxSizing: 'border-box',
    };

    return <PollenContainer
      title="Almost there..."
      subtitle="Just need to verify your phone number"
      error={error}
    >
      <form><Container>
        <Row style={maybeMaxHeight}>
          <Container>
            <Row style={{marginTop: 32}}>
              <Col>
                A text message with a verification code was sent to (***) *** - {props.phoneNumber.substr(props.phoneNumber.length - 4)}.
              </Col>
            </Row>
            <Row style={{marginTop: 16}}>
              <Col>
                Please enter it here.
              </Col>
            </Row>
            <Row style={{marginTop: 2, fontSize: 10}}>
              <Col>
                <a href="#" onClick={() => (resendCode(props.returnId, props.phoneNumber))}>Resend code</a>
              </Col>
            </Row>            
            <Row>
              <Col style={{textAlign: 'center', marginTop: 32}}>
                <ReactCodeInput 
                  type="text"
                  ref={codeRef}
                  fields={6}
                  inputMode="numeric"
                  inputStyle={inputStyle}
                  value={verificationCode} 
                  onChange={(v) => (setVerificationCode(v))}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{marginTop: 16, fontSize: 14, fontWeight: 'bold'}}>
                Some things to know!
                <ul style={{paddingLeft: 20, marginTop: 10}}>
                  <li>Please place your item(s) in a box or by itself in a visible location outside your front door.</li>
                  <li>No need to provide a shipping label, we will take care of that for you!</li>
                  <li>Examples of obvious locations include: the front step, beneath the mailbox, or leaning against your front door.</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row>
          <Container>
            <Row style={{marginTop: 6}}>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row style={{marginTop: 6}}>
              <Col>
                <PrimaryButton 
                  type="submit"
                  variant="primary" 
                  disabled={verificationCode.length < 6}
                  onClick={(e) => {
                    e.preventDefault();
                    phoneVerify(props.returnId, props.phoneNumber, verificationCode, setData, setError, clearVerificationCode);
                  }}
                >
                  <b>Verify</b>
                </PrimaryButton> 
              </Col>
            </Row>
          </Container>
        </Row>
      </Container></form>
    </PollenContainer>
  }
}