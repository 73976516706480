
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(addressOnly, updateQuery, autoCompleteRef, callback) {

  // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
  console.warn(autoCompleteRef.current);
  let observerHack;
  observerHack = new MutationObserver(function() {
    console.warn('MUTATED');
    if (observerHack) {
      observerHack.disconnect();
    }
    autoCompleteRef.current.setAttribute("autocomplete", "new-password");
      // $("#id-of-element").attr("autocomplete", "new-password");
  });

  observerHack.observe(autoCompleteRef.current, {
    attributes: true,
    attributeFilter: ['autocomplete']
  });  

  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: (addressOnly && ["address"]), componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address", "geometry.location"]);
  autoComplete.addListener("place_changed", () => {
    const addressObject = autoComplete.getPlace();
    updateQuery(addressObject);
  });

  callback();
}

export {
  loadScript,
  handleScriptLoad
};
