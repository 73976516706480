import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';

import { Popover } from '@blueprintjs/core';

import { DatePicker, TimePicker } from '@blueprintjs/datetime';

import { Formik } from 'formik';
import * as Yup from 'yup';

import PollenContainer from './PollenContainer';
import TextField from './TextField';
import PrimaryButton from './PrimaryButton';

import { getMinTimes } from '../lib/time';
import moment, {tz} from 'moment';

import {
  Redirect
} from 'react-router-dom';

import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import axios from 'axios';

import _ from 'lodash';

import { loadScript, handleScriptLoad } from '../lib/gmaps';

const GOOGLE_API_KEY = 'AIzaSyAlhM86-6ztDX7Bkb99mVhNpXnrb8POT6c';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/



export default class RefundConfirm extends React.Component {

  autoCompleteRef = React.createRef();

  state = {
    addressQuery: "",
    //
    address: null, // geometry.location.{lat, lng}, formatted_address, address_components
    pickup: null, // date object
    //
    pickupDateDialog: false,
    //
    pickupDate: null, // date object
    //
    returnId: null,
    error: null,
    loading: false
  }

  componentDidMount() {
    const addressOnly = true;

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(addressOnly, (update) => {
        this.setAddress(update);
        this.setAddressQuery(update.formatted_address);
      }, this.autoCompleteRef, () => {
        if (this.props.address) {
          // default lookup
          new window.google.maps.places.AutocompleteService().getPlacePredictions(
            {input: this.props.address}, 
            (predictions) => {
              if (predictions[0]) {
                new window.google.maps.places.PlacesService(this.autoCompleteRef.current).getDetails({
                  placeId: predictions[0].place_id,
                  fields: ["address_components", "formatted_address", "geometry.location"]
                },
                (update) => {
                  this.setAddress(update);
                  this.setAddressQuery(update.formatted_address);
                })
              }
            });
        }
      })
    );
  }

  setAddressQuery = (addressQuery) => {
    this.setState({
      addressQuery,
    });
  }

  setAddress = (address) => {
    this.setState({
      address,
    });
  }

  //
  openPickupDateDialog = () => {
    this.setState({
      pickupDateDialog: true,
    });
  }

  closePickupDateDialog = () => {
    this.setState({
      pickupDateDialog: false,
    });
  }

  setPickupDate = (pickupDate) => {
    this.setState({
      pickupDate,
    });

    this.closePickupDateDialog();
  }

  postRefund = ({email, address, address2, time, instructions, phone}) => {
    this.setState({
      loading: true,
    });

    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();

    // break down components
    const addressComponents = address.address_components;
    const streetNumber = _.find(addressComponents, (item) => (item.types.includes("street_number"))) || {}
    const streetName = _.find(addressComponents, (item) => (item.types.includes("route"))) || {}
    const city = _.find(addressComponents, (item) => (item.types.includes("locality"))) || {}
    const state = _.find(addressComponents, (item) => (item.types.includes("administrative_area_level_1"))) || {}
    const zip = _.find(addressComponents, (item) => (item.types.includes("postal_code"))) || {}

    // address.address_components 

    // 
    axios.post('/api/returns', {
      // we post this no matter what. it is ignored if user is cookied
      client_id: this.props.clientId,
      guest_email: this.props.guest && email,
      //
      address: address.formatted_address,
      address_line1: `${streetNumber.short_name} ${streetName.short_name}`,
      address_line2: address2,
      address_city: city.long_name,
      address_state: state.short_name,
      address_zip: zip.short_name,
      lat,
      lng,
      time: time.getTime(),
      timezone_offset: time.getTimezoneOffset(),
      instructions,
      phone_number: phone,
      // passed in
      items: this.props.items,
      course_of_action: this.props.courseOfAction,
      course_of_action_description: this.props.courseOfActionDescription,
    }).then((resp) => {
      this.setState({
        loading: false,
        isVerified: resp.data.is_verified,
        returnId: resp.data.id,
        phoneNumber: phone,
      });
    }).catch((e) => {
      this.setState({
        loading: false,
        error: e.response.data
      })
    });
  }

  render() {
    if (this.state.returnId) {
      // strip
      if (this.state.isVerified) {
        const redirect = `/oauth/redirect?redirect_uri=${encodeURIComponent(this.props.redirectURI)}&state=${this.props.state}&client_id=${this.props.clientId}&return_id=${this.state.returnId}`;
        return <Redirect to={redirect} />
      } else {
        const redirect = `/verify?redirect_uri=${encodeURIComponent(this.props.redirectURI)}&state=${this.props.state}&client_id=${this.props.clientId}&return_id=${this.state.returnId}&phone_number=${encodeURIComponent(this.state.phoneNumber)}`;
        return <Redirect to={redirect} />
      }
    } else {
      const { validTimes, pickupMoment, minDate } = getMinTimes(moment(), this.state.pickupDate);

      const { innerHeight, innerWidth } = window;
      const isPortrait = innerWidth < innerHeight;

      const maybeMaxHeight = isPortrait ? {
        overflowY: 'scroll',
        maxHeight: (innerHeight - 260)
      } : {};

      return <PollenContainer
        title="We'll come get your stuff"
        subtitle="Set your pickup address, date, and time"
        error={this.state.error}
      >
        <Container>
          <Formik
            initialValues={{
              email: '',
              phone: '',
              instructions: '',
              address2: '',
              pickupTime: validTimes[0].value,
            }}
            onSubmit={(values) => {
              const pickup = pickupMoment.toDate();
              pickup.setHours(values.pickupTime);

              this.postRefund({
                email: values.email,
                instructions: values.instructions,
                phone: values.phone,
                address: this.state.address,
                address2: values.address2,
                time: pickup,
              });
            }}
            validationSchema={Yup.object().shape({
              phone: Yup.string(),
              //.matches(phoneRegExp, 'Phone number is not valid'),
              driverNote: Yup.string(),
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              // date calculations
              
              return <form autoComplete="off" autoCorrect="off">
                <Row style={maybeMaxHeight}>
                  <Col>
                    { this.props.guest && <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="mail"
                          title="Email"
                          hasText={!!values.email}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "email",
                            onChange: handleChange,
                            value: values.email,
                            autoComplete: "off",
                          }}
                          footerText={<span>
                            Or you can <a href={`/register${this.props.params}`}>register</a> for a Pollen account
                          </span>}
                        />
                      </Col>
                    </Row> }
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="location_on"
                          title="Address"
                          hasText={!!this.state.addressQuery.length}
                          disabled={this.state.loading}
                          controlProps={{
                            onChange: (event) => (this.setAddressQuery(event.target.value)),
                            value: this.state.addressQuery,
                            ref: this.autoCompleteRef,
                            autoComplete: "off",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="home"
                          title="Apartment, floor, etc."
                          placeholder="Apartment, floor, etc. (Optional)"
                          hasText={!!values.address2.length}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "address2",
                            onChange: handleChange,
                            value: values.address2,
                            autoComplete: "new-password",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: 10, paddingTop: 16, paddingLeft: 0}}>
                            <i style={{opacity: 0.3}} className="material-icons-outlined">event</i>
                          </div>
                          <Popover 
                            minimal
                            fill
                            autoFocus={false}
                            position="bottom-left"
                            isOpen={this.state.pickupDateDialog}
                            onClose={this.closePickupDateDialog}
                            backdropProps={{
                              style: {width: '100%'}
                            }}
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: false,
                                boundariesElement: document.body,
                              },
                            }}
                          >
                            <div>
                              <div style={{paddingTop: 8, paddingLeft: 13, fontSize: 12, color: '#555', position: 'absolute'}}>
                                Date 
                              </div>
                              <FormControl 
                                style={{
                                  height: 56,
                                  paddingTop: 18,
                                  backgroundColor: 'rgba(57,57,57,0.1)'
                                }}
                                placeholder="Date"
                                value={pickupMoment.format('ddd. MMM. DD YYYY')}
                                onClick={this.openPickupDateDialog}
                              />
                            </div>
                            <div style={{minWidth: 300}}>
                              <DatePicker
                                onChange={this.setPickupDate}
                                value={pickupMoment.toDate()}
                                minDate={minDate.toDate()}
                                highlightCurrentDay={true}
                              />
                            </div>
                          </Popover>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="schedule"
                          title="Time"
                          hasText={!!values.pickupTime}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "pickupTime",
                            as: "select",
                            onChange: handleChange,
                            value: values.pickupTime,
                          }}
                        >
                          {validTimes.map((d) => {
                            return <option value={d.value}>{d.text}</option>
                          })}
                        </TextField>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30}}>
                      <Col>
                        <TextField
                          icon="call"
                          title="Phone Number"
                          hasText={!!values.phone.length}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "phone",
                            onChange: handleChange,
                            value: values.phone,
                            autoComplete: "off",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 30, marginBottom: 30}}>
                      <Col>
                        <TextField
                          icon="chat"
                          title="Pickup Instructions"
                          placeholder="Pickup Instructions (optional)"
                          hasText={!!values.instructions}
                          disabled={this.state.loading}
                          controlProps={{
                            id: "instructions",
                            onChange: handleChange,
                            value: values.instructions,
                            autoComplete: "off"
                          }}
                          footerText="For example, tell them where the package will be waiting."
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Container>
                    <Row style={{marginTop: 6}}>
                      <Col>
                        <hr />
                      </Col>
                    </Row>
                    <Row style={{marginTop: 6}}>
                      <Col>
                        <PrimaryButton 
                          type="submit"
                          variant="primary"
                          onClick={handleSubmit} 
                          disabled={this.state.loading || (!this.state.address || !values.pickupTime || !values.phone)}
                        >
                          <b>Schedule Pickup</b>
                        </PrimaryButton>
                      </Col>
                    </Row>
                    <Row style={{marginTop: 3}}>
                      <Col>
                        <div style={{fontSize: 10, textAlign: 'center'}}>
                          Check inbox for confirmation e-mail.
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Row>
              </form>
            }}
          </Formik>
        </Container>
      </PollenContainer>
    }
  }
}
