import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

export default function PollenContainer(props){
  return <Container style={{fontFamily: "'Montserrat', sans-serif"}}>
    <Row style={{background: 'linear-gradient(224deg, #FEBD00, #FFEEBC)'}}>
      <Col>
        <Container style={{height: 116, padding: 16}}>
          <h3 style={{fontSize: 16}}>{props.title}</h3>
          <h1 style={{fontSize: 19}}><b>{props.subtitle}</b></h1>
        </Container>
      </Col>
      <div style={{position: 'relative', right: 28, top: 92}}>
        <img style={{width: 50, height: 50}} src={process.env.PUBLIC_URL + '/pollen-hex.svg'} />
      </div>
    </Row>
    { props.error && <Row>
      <Col>
        <Alert style={{width: '100%'}} variant="danger">{props.error.message}</Alert>
      </Col>
    </Row> }
    <Row style={{marginTop: 20}}>
      <Col>
        {props.children}
      </Col>
    </Row>
  </Container>
}