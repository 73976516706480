import React from 'react';

import axios from 'axios';
import PrimaryButton from './PrimaryButton';
import { Container, Row, Col } from 'react-bootstrap';
import { 
  Spinner,
} from '@blueprintjs/core';
import moment from 'moment';
import {
  Redirect
} from 'react-router-dom';

export class Cancel extends React.Component {
  state = {
    loading: true,
    data: null,
    locked: false,
    cancelled: false,
  }

  componentDidMount() {
    axios.get(`/api/email-tokens/${this.props.token}/return`).then((resp) => {
      this.setState({
        loading: false,
        data: resp.data
      });
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }
    });
  }

  cancel = () => {
    this.setState({
      locked: true,
    });

    axios.put(`/api/email-tokens/${this.props.token}/status/cancelled`).then((resp) => {
      // goto new page
      this.setState({
        locked: false,
        cancelled: true,
      })
    }).catch((e) => {
      if (e.response && e.response.data.error) {
        this.setState({
          error: e.response.data
        });
      }      
    });
  }

  render() {
    if (!this.props.token) {
      return <div>Error: unauthorized</div>
    }

    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.cancelled) {
      return <Redirect to={`/cancelled?token=${this.props.token}`} />
    }

    return <Container style={{marginTop: 32, maxWidth: 800}}>
      <Row>
        <Col>
          <Container>
            Are you sure you want to cancel your pick up?
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
          <Container>
            <Row><Col style={{fontWeight: 'bold'}}>Date</Col><Col>{moment(this.state.data.pickup_window_start).format('ddd. MMM. DD YYYY')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Time</Col><Col>{moment(this.state.data.pickup_window_start).format('hh:mm A')} - {moment(this.state.data.pickup_window_end).format('hh:mm A')}</Col></Row>
            <Row><Col style={{fontWeight: 'bold'}}>Address</Col><Col>{this.state.data.address.formatted}</Col></Row>
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
        {
          // return info
        }
        <PrimaryButton
          type="submit"
          variant="danger" 
          onClick={this.cancel}
          disabled={this.state.locked}
        >
          <b>Cancel</b>
        </PrimaryButton>
        </Col>
      </Row>
    </Container>
  }
}

export class Cancelled extends React.Component {
  render() {
    return <Container style={{marginTop: 32, maxWidth: 800}}>
      <Row>
        <Col>
          <Container>
            Your pickup has been cancelled.
          </Container>
        </Col>
      </Row>
      <Row style={{marginTop: 32}}>
        <Col>
          {
            // return info
          }
          <PrimaryButton
            type="submit"
            variant="primary" 
            href={`/reschedule?token=${this.props.token}`}
          >
            <b>Reschedule</b>
          </PrimaryButton>
        </Col>
      </Row>      
    </Container>
  }
}
