import moment from 'moment';

const BLACKOUT_SIZE = 24; // hours

const MAX_HOUR = 13;
const HOUR_OPTIONS = [
  {
    text: '9AM-1PM',
    value: 9,
  },
  {
    text: '1PM-5PM',
    value: 13,
  }  
];

export function getMinTimes(current, selectedDate) {
  const baseMinDate = current.add(BLACKOUT_SIZE, 'hours');
  const isPast = baseMinDate.get('hour') > (MAX_HOUR - 1);
  const minDate = isPast ? baseMinDate.clone().add(1, 'day').startOf('day') : baseMinDate;

  const pickupMoment = (selectedDate ? moment.max(moment(selectedDate), minDate) : minDate).clone();
  const validTimes = HOUR_OPTIONS.filter((h) => {
    return pickupMoment.clone().hour(h.value).startOf('hour').isAfter(minDate);
  });

  return {
    pickupMoment: pickupMoment.clone().startOf('day'), //captures maybe selected
    validTimes,
    minDate: minDate.clone().startOf('day'), // minDate
  };
}
