import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
  Redirect
} from 'react-router-dom';

import PollenContainer from './PollenContainer';
import TextField from './TextField';
import PrimaryButton from './PrimaryButton';

const createUser = (email, password, setData, setError, setSubmitting) => {
  axios.post('/api/users', {
    email,
    password,
  }).then((resp) => {
    setData(resp.data);
  }).catch((e) => {
    if (e.response && e.response.data.error) {
      setError(e.response.data.error);
      setSubmitting(false);
    }
  });
};


export default function UserRegister(props) {
  const { params } = props;

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  if (data) {
    return <Redirect
      to={`/refund${params}`}
    />
  } else {
    return <PollenContainer
        title="Register for Pollen"
        subtitle="We'll pick up your stuff & get you your refund"
      >
      <Formik
        initialValues={{email: '', password: ''}}
        onSubmit={(values, { setSubmitting }) => {
          createUser(values.email, values.password, setData, setError, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .min(1)
            .required('Required'),
          password: Yup.string(),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            setSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          return <Container>
            <Row>
              <Col>
                { error && <Alert variant="danger">{error}</Alert> }
              </Col>
            </Row>
            <Row style={{marginTop: 20, maxWidth: 500}}>
              <Col>
                <form>
                  <Row>
                    <Col>
                      <TextField
                        icon="mail"
                        title="Email"
                        hasText={!!values.email}
                        controlProps={{
                          id: "email",
                          onChange: handleChange,
                          value: values.email,
                          autoComplete: "off",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 30}}>
                    <Col>
                      <TextField
                        icon="https"
                        title="Password"
                        hasText={!!values.password}
                        controlProps={{
                          id: "password",
                          onChange: handleChange,
                          value: values.password,
                          type: "password",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 32}}>
                    <Col>
                      <PrimaryButton 
                        type="submit" 
                        variant="primary" 
                        disabled={isSubmitting || !values.password || !values.email}
                        onClick={handleSubmit}
                      >
                        <b>Register</b>
                      </PrimaryButton> 
                    </Col>
                  </Row>
                  <Row style={{marginTop: 32}}>
                    <Col style={{textAlign: 'center'}}>
                      Already have an account?{' '} 
                      <a
                        href={`/login${params}`}
                      >
                        Log In
                      </a>.
                    </Col>
                  </Row>
                  <Row style={{marginTop: 6}}>
                    <Col style={{textAlign: 'center'}}>
                      Or{' '} 
                      <a
                        href={`/guest${params}`}
                      >
                        return as a guest
                      </a>.
                    </Col>
                  </Row>                  
                  <Row style={{marginTop: 32}}>
                    <Col style={{textAlign: 'center'}}>
                      <div>By registering you agree to Pollen's</div>
                      <div><a href="#">Terms of Service.</a></div>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        }}
      </Formik>
    </PollenContainer>
  }
}
